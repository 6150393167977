/* STYLESHEET FOR KURVE.SE */

body {
    background-color: black;
    color: rgba(255, 255, 255, 0.5);
}

a:hover, a:focus, a:active {
    color: rgba(255, 255, 255, 1.0);
}

main {
    background: black url("../resources/kurve-splash.png");
    box-sizing: border-box;
    height: 480px;
    padding-top: 288px;
    position: relative; /* so we can position footer absolutely */
    text-align: center;
    width: 640px;
}

#wrapper p {
    margin: 0 0 1em 0;
}

#start-hint {
    font-size: 1.2em;
}

#fullscreen-hint, #popup-hint {
    font-size: 0.9em;
}

kbd {
    color: white;
}

#popup-hint a {
    text-decoration: underline;
}

main footer {
    bottom: 0;
    height: 40px;
    position: absolute;
    width: 100%;
}

#preload {
    display: none;
}
